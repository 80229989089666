import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

const initialRows = [
  {
    id: 1,
    link: '', 
    option: '', 
    quantity: '', 
    price: ''
  },
];



export default function CellEditControlGrid(props) {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [saveHeading, setSaveHeading] = React.useState('儲存');
  const [editMode, setEditMode] = React.useState(false);

  useEffect(() => {
    if (editMode) { 
      setSaveHeading('取消')
    } else {
      setSaveHeading('刪除')
      props.productList(rows);
      console.log(checkNegative());
      console.log(checkEmpty());
      props.error(checkEmpty() || checkNegative());
    }
  }, [editMode])

  const checkEmpty = () => {
    let empty = false
    rows.map((item) => {
      if ((item.link === '' || item.quantity === '' || item.price === '')) {
        empty = true
        props.errorMsg('請輸入所有所需貨品資料（網址/數量/單價)')
      }
    })
    return empty
  }

  const checkNegative = () => {
    let negative = false
    console.log(rows)
    rows.map((item) => {
      if ((item.quantity <= 0 || item.price <= 0)) {
        negative = true
        props.errorMsg('貨品數量及單價均不能少於0')
      }
    })
    return negative
  }

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = rows.length+1;
      setRows((oldRows) => [...oldRows, { id, link: '', option: '', quantity: '', price: ''}]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'link' },
      }));
    };
  
    return (
      <GridToolbarContainer>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleClick} style={{backgroundColor: '#3B270C'}}>
          新增商品
        </Button>
      </GridToolbarContainer>
    );
  }


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = false;
    }
  };
 
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    updateId(id);
  };

  const updateId = (id) => {
    console.log(id);
    rows.map((item) => {
      if (item.id > id) {
        item.id = item.id - 1;
      }
    })
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'id', headerName: '項目',
    //  width: 50,
      minWidth: 50,
      flex: 0.5,
     editable: false, sortable: false},
    { field: 'link', headerName: '貨品名稱', 
    // width: 300, 
    minWidth: 300,
    flex: 3,
    editable: true, sortable: false },
    {
      field: 'option',
      headerName: '備註',
      minWidth: 200,
      // width: 200,
      flex: 2,
      editable: true,
      sortable: false,
      type: 'singleSelect',
      valueOptions: ['淘寶', 'Amazon']
    },
    {
      field: 'quantity',
      headerName: '數量',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      minWidth: 100,
      // width: 100,
      flex: 1,
      editable: true,
      sortable: false
    },
    {
      field: 'price',
      headerName: '單價',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      minWidth: 100,
      flex: 1,
      // width: 100,
      editable: true,
      sortable: false
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: saveHeading,
      minWidth: 80,
      // width: 80,
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        setEditMode(isInEditMode);

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableColumnMenu={true}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}