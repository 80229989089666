import React from 'react';
import {Page, Text, Image, Document, StyleSheet, View, Font} from '@react-pdf/renderer';
import TW from '../TW-Kai-98_1.ttf'
import * as uuid from "uuid";
import stamp from './stamp.png';
import logo from './logo.jpeg';

Font.register({
    family: 'TW-Kai-98_1',
    src: TW
})
Font.registerHyphenationCallback((word) =>
  Array.from(word).flatMap((char) => [char, ''])
)

const styles = StyleSheet.create({
    con: {
        marginRight: '20px', 
        marginLeft: '15px',
        height: '100vh'
    },
    header: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        fontFamily: 'TW-Kai-98_1',
        display: 'flex',
        width: '100%'
    },
    docImage: {
        width: '180px',
        height: '80px',
        marginLeft: '30px'
    },
    detailColumn: {
        flexDirection: 'column',
        flexGrow: 9,
        fontFamily: 'TW-Kai-98_1',
        fontSize: '24px',
        marginBottom: '1px',
        marginTop: '20px'
    },
    detailRow: {
        flexDirection: 'column',
        fontFamily: 'TW-Kai-98_1',
    },
    midRow: {
      flexDirection: 'row',
      fontFamily: 'TW-Kai-98_1',
      marginBottom: '30px',
      marginLeft: '15px',
    },
    rightColumn: {
        display: 'flex',
        color: 'black',
        textDecoration: 'none',
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
        fontFamily: 'TW-Kai-98_1',
        fontSize: '12px',
    },
    leftColumn: {
        fontFamily: 'TW-Kai-98_1',
        fontSize: '12px',
        flexDirection: 'column',
        width: '53%',
        marginLeft: '5px',
        alignSelf: 'flex-start',
        alignItems: 'flex-start'
    },
    table: {
        fontFamily: 'TW-Kai-98_1',
        width: '95%',
        marginLeft: '15px',
        fontSize: '12px',
        display: 'flex'
      },
      row: {
        fontFamily: 'TW-Kai-98_1',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px'
      },
      tableHeader: {
        fontFamily: 'TW-Kai-98_1',
        borderTop: 'none',
        fontSize: '12px'
      },
      bold: {
        fontFamily: 'TW-Kai-98_1',
        fontWeight: 'bold',
      },
      // So Declarative and unDRY 👌
      row1: {
        fontFamily: 'TW-Kai-98_1',
        width: '10%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row2: {
        fontFamily: 'TW-Kai-98_1',
        width: '45%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row3: {
        fontFamily: 'TW-Kai-98_1',
        width: '10%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row4: {
        fontFamily: 'TW-Kai-98_1',
        width: '9%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row5: {
        fontFamily: 'TW-Kai-98_1',
        width: '13%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row6: {
        fontFamily: 'TW-Kai-98_1',
        width: '13%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row7: {
        fontFamily: 'TW-Kai-98_1',
        width: '64%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row8: {
        fontFamily: 'TW-Kai-98_1',
        width: '100%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px',
        fontSize: '12px'
      },
      docFooter: {
        borderTopWidth: 1,
        borderTopColor: '#112131',
        borderTopStyle: 'solid',
        fontSize: '8px',
        fontFamily: 'TW-Kai-98_1',
        marginTop: 15,
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%'
      },
  });

  const PdfFile = (props) => {
    console.log(props.total)
    console.log(props.surcharge)
    let month = new Date().getMonth()+1;
    if (month.toString().length === 1) {
        month = '0' + month;
    }

    return (
      <Document>
        <Page style={styles.body}>
            <View style={styles.con}>
                <View style={styles.header}>
                    <View style={styles.detailRow}>
                        <Image src={logo} style={styles.docImage}></Image>
                        <Text style={{fontSize: '12px', marginLeft: '78px', marginBottom: '8px'}}>註冊電業承辦商</Text>
                    </View>
                    <View style={styles.detailColumn}>
                        <Text style={{alignSelf: 'flex-end', color: '#006CA5'}}>亞孟森林工程有限公司</Text>
                        <Text style={{alignSelf: 'flex-end', color: '#006CA5', fontSize: '15px', marginTop: '10px'}}>Amundsen Lam Engineering Co. Ltd.</Text>
                        <Text style={{alignSelf: 'flex-end', marginRight: '65px', marginBottom: '2px', fontSize: '12px', marginTop: '10px'}}>STEAM教育課程顧問</Text>
                    </View>
                </View>
                <View style={{color: '#797EF6', fontFamily: 'TW-Kai-98_1', display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '20px', fontSize: '20px'}}>
                    <Text style={{}}>電子報價單</Text>
                    <Text>Electronic Quotation</Text>
                </View>
                <View style={styles.midRow}>
                  <View style={styles.leftColumn}>
                      <Text style={{}}>日期: {new Date().toLocaleString("en-US", { day : '2-digit'})}/{month}/{new Date().getFullYear().toString().slice(-2)}</Text>
                      <Text style={{}}>報價編號: {props.fileNum.toString().substring(5,13)}</Text>
                  </View>
                  <View style={styles.rightColumn}>
                      <Text>{props.company}</Text>
                      <Text>{props.address}</Text>
                      <Text>{props.name}</Text>
                  </View>
                </View>

                <View>
                    <View style={styles.table}>
                        <View style={[styles.row, styles.bold, styles.tableHeader]}>
                            <Text style={styles.row1}>項目</Text>
                            <Text style={styles.row2}>貨品</Text>
                            <Text style={styles.row4}>數量</Text>
                            <Text style={styles.row5}>單價</Text>
                            <Text style={styles.row6}>金額</Text>
                            <Text style={styles.row3}>備註</Text>
                        </View>
                        {props.productList.map((productList, i) => (
                            <View key={i} style={styles.row} wrap={false}>
                                <Text style={styles.row1}>{i+1}</Text>
                                <Text style={styles.row2}>{productList.link}</Text>
                                <Text style={styles.row4}>{productList.quantity}</Text>
                                <Text style={styles.row5}>${productList.price.toFixed(2)}</Text>
                                <Text style={styles.row6}>${(productList.price*productList.quantity).toFixed(2)}</Text>
                                <Text style={styles.row3}>{productList.option ? (productList.option) : ('-')}</Text>
                            </View>
                        ))}
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <Text style={styles.row7}>服務費</Text>
                            <Text style={styles.row5}>HK$</Text>
                            <Text style={styles.row6}>{props.surcharge === 95 ? ((95).toFixed(2)) : ((props.total*props.surcharge).toFixed(2))}</Text>
                            <Text style={styles.row3}></Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <Text style={styles.row7}>總額</Text>
                            <Text style={styles.row5}>HK$</Text>
                            <Text style={styles.row6}>{props.surcharge === 95 ? ((props.total + 95).toFixed(2)) : ((props.surcharge*props.total)+props.total).toFixed(2)}</Text>
                            <Text style={styles.row3}></Text>
                        </View>
                        <View style={styles.row8}>
                          <Text>備註：</Text>
                          <Text>1. 本自動報價是根據貴公司在網頁輸入的資料自動生成，並不構成本公司與貴公司的商業合約或服</Text>
                          <Text style={{marginLeft: '18px'}}>務承諾。</Text>
                          <Text>2.	若需正式報價單，請把代購物品資料及以上報價編號電郵至 amundsenlamengg@gmail.com，以便</Text>
                          <Text style={{marginLeft: '18px'}}>本公司跟進。</Text>
                          <Text>3. 以上報價未包括運輸費用。</Text>
                      </View>
                    </View>
                   
                    <View style={{fontFamily: 'TW-Kai-98_1', alignSelf: 'flex-end', justifySelf: 'flex-end', marginTop: '100px', fontSize: '12px'}}>
                      <View style={{flexDirection: 'row', alignSelf: 'flex-end'}}>
                        <Image src={stamp} style={{width: '60px', alignSelf: 'flex-end'}}></Image>
                        <Text style={{alignSelf: 'flex-end'}}>經手人：黃小姐</Text>
                      </View>
                      <Text style={{fontSize: '8px'}}>(This electronic quotation is issued without signature)</Text>
                    </View>
                    <View style={styles.docFooter}>
                        <View style={{marginTop: '2px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '10px'}}>
                            <Text>新界沙田小瀝源谷花心坑沙田丈量約份193號地段991A段 </Text>
                            <Text>電郵: amundsenlamengg@gmail.com</Text>
                            <Text>電話：(852) 6644 9732</Text>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
      </Document>
    );
  };
  
  export default PdfFile;