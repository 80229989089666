import * as React from 'react';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useLocation }  from "react-router-dom";
import PdfFile from './PdfFile';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const Download = () => {
    const {state} = useLocation();
    const {company, address, name, productList, total, surcharge} = state;
    const navigate = useNavigate();
    document.body.style.background = '#7C4700';
    
    const fileNum = new Date().valueOf() + Math.random()
    const pdffile = <PdfFile 
                    company={company}
                    address={address}
                    name={name}
                    productList={productList}
                    total={total}
                    surcharge={surcharge}
                    fileNum={fileNum} />
    
    const handleClick = () => {
        navigate('/');
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{marginBottom: '1%', marginTop: '1%', background: 'white', borderRadius: '30px', width: '90%', height: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', paddingBottom: '10px'}}>
                
                <div style={{alignSelf: 'center', marginTop: '5px', fontSize: '20px'}}>以下為自動報價結果</div>
                <PDFViewer height={window.innerHeight} width="100%" style={{alignSelf: 'center', marginTop: '5px'}}>
                    {pdffile}
                </PDFViewer>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <PDFDownloadLink document={pdffile} fileName="quotation.pdf" style={{alignSelf: 'center', marginTop: '5px'}}>
                        {({blob, url, loading, error}) => (loading ? <Button disabled variant="contained" style={{backgroundColor: '#3B270C'}}>Loading Document...</Button> : <Button variant="contained" style={{backgroundColor: '#3B270C'}}>下載報價單</Button> )}
                    </PDFDownloadLink>
                    <Button variant="contained" onClick={handleClick} style={{backgroundColor: '#3B270C', marginLeft: '30px', width: '100px', height: '35px', marginTop: '6px'}}>返回</Button>
                </div>
            </div>
          
        </div>
      );
}
export default Download